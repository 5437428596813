import React, { useEffect, useRef, useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import ThumbnailCard from "../../components/Cards/ThumbnailCard";
import Filters from "../../components/Filters/Filters";
import { useDispatch, useSelector } from "react-redux";

import { getAllMeetings } from "../../../redux/actions/meeting.action";
import {
  getRecordedConference,
  registerLiveEvents,
} from "../../../redux/actions/conference.action";
import { useNavigate, Link, useParams } from "react-router-dom";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import InfiniteScroll from "../../components/InfiniteScroll/InfiniteScroll";
import {
  formatDate,
  formattTime,
  timeAgo,
  getStatusFromDate,
  isMeetingEnd,
} from "../../../utils/core";
import ProfileComplete from "../../components/CompleteProfile/ProfileComplete";

import {
  CLEAR_LOADING,
  SET_LOADING,
} from "../../../redux/constants/globalActionTypes";

// import Brochure from "../../../img/live_events/HSICON-2024-SCHEDULE.pdf";

const RecordedConferenceDetails = () => {
  let navigate = useNavigate();
  const isInitialMount = useRef(true);

  const dispatch = useDispatch();
  const { eventId } = useParams();

  const [currentUser, setCurrentUser] = useState(
    localStorage.getItem("userId")
  );

  const [conferenceList, setConferenceList] = useState([]);

  useEffect(() => {
    dispatch({ type: SET_LOADING });
    const getConference = async () => {
      dispatch(getRecordedConference({ page: 1 }, true)).then((response) => {
        if (response.data.status.success) {
          const uniqueData = response.data.data.filter(
            (item) => item.event_id === eventId
          );

          setConferenceList(uniqueData);
        }
      });
    };

    getConference();
  }, []);

  const handleOpenPdf = (pdfUrl) => {
    window.open(pdfUrl, "_blank"); // Opens the PDF in a new tab
  };

  const watchConference = (url, title) => {
    let urlArray = url.split("/");
    if (urlArray[2] === "vimeo.com") {
      navigate(
        `/player/recorded-conference?title=${title}&code=${urlArray[3]}`
      );
    } else {
      window.open(url, "_blank");
    }
  };

  return (
    <Root>
      <Breadcrumb
        title={"Recorded Conference"}
        fieldOne="Dashboard"
        fieldTwo="Recorded Conference"
      />
      <ContainerWrapper>
        {/* <ProfileComplete /> */}
        <div className="row mt-2">
          <div className="col-12 d-lg-flex justify-content-lg-between">
            <div>
              <h2 className="h4 mb-1">Recorded Conference</h2>
              <p>Total {conferenceList.length} Conference</p>
            </div>
            {/* <div className=" d-lg-block">
              <button
                title="Recording of Conference"
                className="btn btn-primary mt-2"
                onClick={() => {
                  navigate("/myCmeRtm");
                }}
              >
                Recording of Conference{" "}
                <i className="fa-solid fa-arrow-right"></i>
              </button>
            </div> */}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="bg-white card rounded-3 mb-4">
              {conferenceList.length > 0 ? (
                <div className="card-body px-4 py-3">
                  <div className="table-responsive">
                    <table className="table table-borderless table-striped table-lg align-middle cme-table pb-0">
                      <thead className="">
                        <th>Topic</th>
                        <th className="">Actions</th>
                      </thead>
                      <tbody>
                        {conferenceList.map((conference, index) => (
                          <>
                            <tr>
                              <td className="fw-bold">
                                <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                  Topic:
                                </p>
                                <h6 className="mb-0">{conference?.title}</h6>
                              </td>

                              <td className="text-left">
                                {/* <button
                                  rel="noopener noreferrer"
                                  className="btn btn-primary mb-2 m-2 btn-sm"
                                  onClick={() =>
                                    handleOpenPdf(conference.brochure)
                                  }
                                >
                                  Brochure{" "}
                                  <i className="fa-solid fa-arrow-right"></i>
                                </button> */}
                                <button
                                  data-toggle="collapse"
                                  href={`#brocher${index}`}
                                  role="button"
                                  aria-expanded="false"
                                  aria-controls={`brocher${index}`}
                                  className="btn btn-primary m-2 btn-sm"
                                >
                                  Watch Now{" "}
                                  <i className="fa-solid fa-arrow-down"></i>
                                </button>
                                <>
                                  {/* <button
                                    data-toggle="collapse"
                                    href={`#watch${index}`}
                                    role="button"
                                    aria-expanded="false"
                                    aria-controls={`watch${index}`}
                                    className="btn btn-primary m-2 btn-sm"
                                  >
                                    Watch Now{" "}
                                    <i className="fa-solid fa-arrow-down"></i>
                                  </button> */}
                                </>
                              </td>
                            </tr>

                            <tr>
                              <td colSpan="2">
                                <div
                                  className="collapse multi-collapse"
                                  id={`brocher${index}`}
                                >
                                  <div className="row">
                                    {conference?.recordingUrls?.map(
                                      (res, key) => (
                                        <div
                                          key={key}
                                          className="col-md-4 mb-3"
                                        >
                                          <Card style={{ width: "100%" }}>
                                            <div className="card-thumb-wrap">
                                              <iframe
                                                width="100%"
                                                height="170px"
                                                src={res.brochure}
                                                title="Surgicshare Document viewer"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                              ></iframe>
                                            </div>

                                            <Card.Body>
                                              <Card.Title>
                                                {res.button}
                                              </Card.Title>
                                              {/* <Card.Text>
                                                Some quick example text to build
                                                on the card title and make up
                                                the bulk of the card's content.
                                              </Card.Text> */}
                                              <Button
                                                variant="primary"
                                                onClick={() =>
                                                  handleOpenPdf(res.brochure)
                                                }
                                              >
                                                Brochure
                                              </Button>
                                              <Button
                                                variant="primary"
                                                className="ms-2"
                                                onClick={() =>
                                                  watchConference(
                                                    res.url,
                                                    conference?.title
                                                  )
                                                }
                                              >
                                                Watch Now
                                              </Button>
                                            </Card.Body>
                                          </Card>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </table>

                    {/* Watch all */}
                  </div>
                </div>
              ) : (
                <NoDataFound />
              )}
            </div>
          </div>
        </div>
      </ContainerWrapper>
    </Root>
  );
};

export default RecordedConferenceDetails;
